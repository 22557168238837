import React, {useState, useRef} from 'react';
import './App.css';
import Index from './components/Index.js'
import ImprintSite from './components/Imprint'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

class App extends React.Component {

    render() {
        return (
            <Router>
                <div>


                    {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                    <Switch>
                        <Route path="/imprint">
                            <Imprint />
                        </Route>
                        <Route path="/">
                            <Home />
                        </Route>
                    </Switch>
                </div>
            </Router>
        )
    }
}

function Home() {
    return <Index />;
}

function Imprint() {
    return <ImprintSite />;
}

export default App;
