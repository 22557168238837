import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function AppHeader() {
    const classes = useStyles();
    return (
        <div>
            <hr className={"topBorder"}/>
            <div className={"AppHeader"}>

                <Link to="/">Der-Schulden-Rechner.de</Link>
                <hr/>
                <p className={"aboutThaSite"}>Die <b>Laufzeit eines Kredits, Darlehens oder von Schulden</b> berechnen? Jetzt so einfach und modern wie nie, mit dem <b>Schuldenrechner</b>. Dazu den Schuldbetrag oder Kreditbetrag, monatliche Raten und den Zinssatz eingeben und der <b>Schuldenrechner berechnet dir die Laufzeit, bis deine Schuld</b> oder dein Darlehen abbezahlt ist. Probiere es einfach aus und berechne die Laufzeit, einfach, schnell und ohne Finanzwissenschaft.</p>
                <hr/>
            </div>
        </div>


    )
}
