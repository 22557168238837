import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function AppFooter() {
    const classes = useStyles();
    return (
        <div>

            <div className={"AppFooter"}>
                <h2>Für was brauche ich einen Kreditrechner?</h2>
                <p>Ein Schuldenrechner kann dir eine einfache und schnelle Indikation liefern, wie lange du an einem Kredit oder einer Schuld noch abzuzahlen hast. Zur einfacheren Verwendung hast du bei unserem Abzahlungsrechner noch die Möglichkeit neben einer Schuld noch beliebig viele andere mit aufzunehmen. Der ausgegebene Wert kann dir dann eine erste Indikation geben, wie lange du an deinem Kredit oder Darlehen noch abzuzahlen hast.</p>
                <hr />
                <h3>Wie lange muss ich an meinen Schulden abzahlen?</h3>
                <p>Du möchtest wissen wie lange du an deinen Schulden abzahlen musst? Dann kannst du ganz einfach deinen monatlichen Abzahlungsbetrag und den Zinssatz in unseren Schuldenrechner eingeben und damit ganz schnell, einfach und kostenlos die Laufzeit berechnen wie lange du noch an deinem Kredit abzahlen musst.</p>
                <h2>Was kann der Schuldenrechner?</h2>
                <p>Der Schuldenrechner kann dir aus deinem Gesamtkreditvolumen, einer Tilgungsrate und einem aktuellen Zinssatz die Laufzeit berechnen, die es noch dauern wird, bis dein Kredit abbezahlt ist. Diese einfache Möglichkeit der Tilgungsrechnung kann dir einen sehr guten Überblick liefern, wie lange du einen bestimmten Kredit noch abzubezahlen hast. Dazu gibst du einfach deine Gesamtschuld in den Schuldenrechner ein und mit Hilfe der monatlichen Rate und dem Zinssatz kannst du dann deine Restschuld berechnen.</p>
                <hr />
                <h2>Wo finde ich den aktuellen Zinssatz für den Schuldenrechner?</h2>
                <p>Der Zinssatz, der für dein Darlehen oder deine Schuld anfällt, sollte in deinen Unterlagen vermerkt sein. Wenn du diese gerade nicht parat hast, kannst du auch hier auch den <a target="_blank" href="https://www.vergleich.de/kreditzinsen.html">aktuellen Zinssatz</a> recherchieren. Danach den Zinssatz und monatliche Rate einfach in den Tilgungsrechner eingeben und Laufzeit berechnen!
                </p>
                <hr />
                <h4>Welche Vorteile bietet diser Schuldenrechner?</h4>
                <p>
                    Unser Schuldenrechner hat den Vorteil, dass er einfach zu verstehen ist und kinderleicht zu bedienen. Du kannst mehrere Kredite oder Schulden hinzufügen und dann gesamthaft für alle die Laufzeit und den Abzahlungszeitpunkt in Monaten oder Jahren bestimmen. Das macht unseren Schuldenrechner zu einem besonderen im Netz.
                </p>
                <hr />
                <h3>Wie funktioniert die Berrechnung der Laufzeit?</h3>
                <p className={"aboutThaSite"}>Der Schuldenrechner berechnet dir die Laufzeit deiner Schuld. Verwendet wird dabei die <b>Formel zur Berechnung eines <a title={"Wikipedia zur Annuitätenformel - Berechnung der Schuldlaufzeit"} target={"_blank"} href="https://de.wikipedia.org/wiki/Annuit%C3%A4tendarlehen">Annuitätendarlehens</a></b>, umgestellt zur Laufzeit und unter Beachtung von monatlichen Raten. Aber auch eine Angabe in Jahren runden wir dir. Mit dem Schuldenrechner ganz einfach die <b>Laufzeit deiner Schulden</b> herausfinden</p>
                <p><Link to="/Imprint">Impressum</Link> | <a target={"_blank"} href="https://www.der-finanz-rechner.de/">Allgemeiner Finanzrechner</a></p>
                <p>&copy; Lutz Preuss 2020</p>
            </div>


        </div>



    )
}
