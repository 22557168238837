import React, {useState, useRef} from 'react';
import '../App.css';
import CalculationFormList from './CalculationFormList';
import AppHeader from './AppHeader.js';
import AppFooter from './AppFooter.js';
import Grid from "@material-ui/core/Grid";


class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: ''
        };
    }

    handleChangeValue = e => this.setState({value: e.target.value});



    render() {
        return (
            <div>

                <div className="App">

                    <div className="App-header">

                        <AppHeader/>
                        <div className="innerCard">
                            <div>
                                <form noValidate autoComplete="off">

                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12}>
                                            <CalculationFormList
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        </div>
                        <AppFooter/>
                    </div>
                </div>


            </div>
        )
    }
}




export default Index;
