import React from 'react';
import CalculationForm from './CalculationForm';
import Button from "@material-ui/core/Button";
import '../App.css';
import Grid from "@material-ui/core/Grid";

class CalculationFormList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            id: 2,
            text: '',
            wholeResult: '',
            clear: false,
            calculationRows: [<CalculationForm widthId={1} handler={this.handler}/>]
        };

        this.handler = this.handler.bind(this)
        this.showResult = this.showResult.bind(this)
    }



    handler = (nameOfTheState, valueOfResult) => {
        this.setState({
            [nameOfTheState]: valueOfResult
        })
    }


    addForm = () => {
        this.setState({
            id: this.state.id + 1
        });

        this.setState({
            calculationRows: [...this.state.calculationRows,
                <CalculationForm clear={this.state.clear} widthId={this.state.id} handler={this.handler}/>]
        });
    };

    clearForm = () => {
        this.setState({
                id: this.state.id + 1,
                moneyValue: '',
                oneRate: '',
                percentage: '',
                result: '',
                clear: true,
                calculationRows: [<CalculationForm clear={this.state.clear} widthId={1} handler={this.handler}/>],
                wholeResult: 0,
            });


    };

    showResult() {
        let i = 1;
        var wholeValue = 0;
        var oneResult = 0;
        while (i < this.state.id) {
            oneResult = 0;
            let nameOfRow = 'RowId' + i;

            oneResult = this.state[nameOfRow];
            if (!isNaN(oneResult)) {
                wholeValue = oneResult + wholeValue;
            }

            console.log(nameOfRow + ' ' + oneResult);
            i++;
        }

        this.setState({
            wholeResult: Math.ceil(wholeValue)
        });
    }

    renderOutput(months) {
        const years = Math.round(months / 12);
        if (months > 0) {
            return (
                <div>
                    <span>In <b>{months} Monaten</b> bist du Schuldenfrei!</span><br/>
                    <span>Das sind ca. {years} Jahre</span><br />
                    <span class="small">Alle Angaben ohne Gewähr!</span>
                </div>

            );
        } else {
            return (
                <div>
                    <span>Werte eingeben und berechnen wie lange du zur Abzahlung deiner Schulden benötigst.</span>
                </div>
            );
        }
    }

    render() {


        return (

            <div>

                {this.renderOutput(this.state.wholeResult)}

                <hr/>

                {this.state.calculationRows}

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <Button fullWidth variant="contained"
                                color="light" onClick={this.addForm}>
                            Schulden hinzufügen +
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button className={'margiTopMeNegative'} fullWidth variant="contained"
                                color="primary" onClick={this.clearForm}>
                            Zurücksetzen
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button className={'margiTopMeNegative'} size={"large"} fullWidth variant="contained"
                                color="secondary" onClick={this.showResult}>
                            Berechnen
                        </Button>
                    </Grid>
                </Grid>
            </div>


        )
    }
}

export default CalculationFormList;
