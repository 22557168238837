import React, {useState, useRef} from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import CalculationFormList from "./CalculationFormList";


class CalculationForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            moneyValue: '',
            oneRate: '',
            percentage: '',
            result: '',
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (this.props.clear == true ) {
            this.setState({
                moneyValue: '',
                oneRate: '',
                percentage: '',
                result: '',
                });
        }
    }

    handleChange(evt) {
        const value = evt.target.value;


        this.setState({
            ...this.state,
            [evt.target.name]: value,
        }, this.updateStates
            );


    }

    updateStates (){
        const nameOfChild = "RowId" + this.props.widthId
        const moneyValue = parseInt(this.state.moneyValue);
        const oneRate = parseInt(this.state.oneRate);
        const percentage = parseInt(this.state.percentage)/100;

        const resultState = Math.log((1+((percentage/12)/(oneRate/moneyValue-percentage/12)))) / Math.log(1+(percentage/12));

        this.setState({
            result: resultState,
        }, this.updateParent(nameOfChild, resultState)
            );
    }

    updateParent(nameOfResult, valueOfResult){
        this.props.handler(nameOfResult, valueOfResult)
    }

    render() {
        return (

            <div>
                <Grid container spacing={2}>
                    {/*<Grid item xs={12} sm={1}>*/}
                    {/*    {this.props.widthId}*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} sm={5}>
                        <TextField fullWidth type="number" id="outlined-basic" name="moneyValue" label="Schuldbertag"
                                   variant="outlined"
                                   value={this.state.moneyValue}
                                   onChange={this.handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <TextField fullWidth type="number" id="outlined-basic" name="oneRate" label="Rate"
                                   variant="outlined"
                                   value={this.state.oneRate}
                                   onChange={this.handleChange}
                                   InputProps={{
                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                        }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField fullWidth type="number" id="outlined-basic" name="percentage" label="Zins"
                                   variant="outlined"
                                   value={this.state.percentage}
                                   onChange={this.handleChange}
                                   InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        />
                    </Grid>

                   {/* <Grid item xs={12} sm={1}>
                        <TextField fullWidth id="outlined-basic" name="result" value={this.state.result}
                                   label="="
                                   variant="outlined"
                        />

                    </Grid>*/}

                </Grid>
                <hr />
            </div>


        )
    };
}

export default CalculationForm;
